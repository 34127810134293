.globalCentered {
    display: flex;
    justify-content: center;
    align-items: center;
}

.MuiOutlinedInput-notchedOutline{
    border: 2px solid #333333 !important;
}
.MuiOutlinedInput-input.MuiInputBase-input{
    color: white !important;
}